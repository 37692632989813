.container-specific {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.container-specific-2 {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.image-specific {
  height: 200px;
  width: 200px;
  border-radius: 20px;
  cursor: pointer;
}

.image-specific-2 {
  height: 250px;
  width: 500px;
  border-radius: 20px;
  cursor: pointer;
}

.name-specific {
  font-size: 20px;
  font-weight: bold;
  color: #e94514;
  margin-bottom: 3px;
}

.price-specific {
  font-size: 18px;
  margin-bottom: 20px;
}

@media (max-width: 575.98px) {
  .container-specific {
    width: 150px;
  }

  .image-specific {
    height: 150px;
    width: 150px;
  }

  .name-specific {
    width: 150px;
    font-size: 17px;
  }

  .price-specific {
    font-size: 16px;
  }

  .image-specific-2 {
    height: 45vw;
    width: 90vw;
  }

  .container-specific-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: -1.8vw;
  }

  .name-specific-2 {
    width: 300px;
    font-size: 18px;
    font-weight: bold;
    color: #e94514;
    margin-bottom: 3px;
  }
}