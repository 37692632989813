.button-extra {
  margin-top: 40px;
  border: 5px solid #e94514;
  border-radius: 20px;
  height: 50px;
  width: 120px;
  font-size: 20px;
  font-weight: bold;
  color: #e94514;
}

.box-extra {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.container-extra {
  background-image: linear-gradient(to bottom, #000 50%, #333 100%);
  padding: 50px;
  height: 600px;
  width: 400px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  margin: -350px -250px;
  border-radius: 20px;
  border: 2px solid #fff;
}

.title-extra {
  color: #e94514;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
}

.ingredients-extra {
  display: flex;
  flex-direction: column;
}

.button-container-extra {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-extra-list {
  margin-top: 30px;
  font-size: 30px;
  border: none;
  background-color: inherit;
  font-family: inherit;
  padding: 0;
}

.confirm-button-extra {
  margin-top: 55px;
  border: 5px solid #e94514;
  border-radius: 20px;
  height: 50px;
  width: 150px;
  font-size: 20px;
  font-weight: bold;
  color: #e94514;
}

.select-position {
  text-align: center;
}

.close-extra {
  font-size: 40px;
  position: absolute;
  margin-left: 400px;
  margin-top: -80px;
  cursor: pointer;
}

@media (max-width: 575.98px) {
  .button-extra {
    margin-top: 20px;
    height: 40px;
    width: 100px;
    border: 3px solid #e94514;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .container-extra {
    padding: 20px;
    height: 580px;
    width: 300px;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(23%, 7%);
  }

  .title-extra {
    font-size: 25px;
  }

  .confirm-button-extra {
    margin-top: 30px;
  }

  .font-size-extra {
    font-size: 20px;
    position: relative;
    top: -7px;
  }

  .close-extra {
    margin-left: 280px;
    margin-top: -40px;
  }
}