.bg-single {
  background-image: linear-gradient(to bottom, #000 50%, #333 100%);
  height: auto;
  color: white;
}

.container-single {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.amount-box-single {
  display: flex;
  flex-direction: row;
}

.value-button-single {
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e94514;
  font-size: 80px;
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
}

.value-button-single#decrease {
  padding-bottom: 15px;
}

.amount-single {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 65px;
  color: #fff;
  width: 60px;
  height: 48px;
}

.addcart-single {
  margin: 20px 0;
  height: 60px;
  width: 400px;
  font-size: 24px;
  font-weight: bold;
  color: #e94514;
  border: 7px solid #e94514;
  border-radius: 20px;
}

.alert-single {
  color: red;
  font-weight: bold;
  font-size: 30px;
  margin-top: 20px;
  margin-left: 50px;
}

@media (max-width: 575.98px) {
  .addcart-single {
    border: 5px solid #e94514;
    height: 50px;
    width: 300px;
    font-size: 20px;
  }

  .value-button-single {
    font-size: 50px;
  }

  .amount-single {
    font-size: 50px;
  }
}