.container {
  height: 200px;
  width: 200px;
  background-color: #fff;
  border: 8px solid #e94514;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #e94514;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 60px;
}

.up {
  margin-top: 10px;
}

.img {
  height: 130px;
  width: 130px;
}

.down {
  margin-top: 10px;
}

@media (max-width: 575.98px) {
  .container {
    height: 150px;
    width: 150px;
    border: 5px solid #e94514;
    margin-bottom: 30px;
  }

  .img {
    height: 90px;
    width: 90px;
  }

  .down {
    font-size: 25px;
  }
}