.negozi {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-negozi {
  margin-bottom: 20px;
  font-family: inherit;
  font-size: 25px;
  color: #E94501;
}

.negozio-address{
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: small;
  color: aliceblue;
}

.logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.logo-image {
  width: 300px;
  height: 300px;
}

.image-container {
  position: absolute;
  top: 45%;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.image-container img {
  border-radius: 5%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  max-width: 350px;
  max-height: 350px;
}

.align-images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .logo-image {
    height: 400px;
    width: 400px;
  }

  .image-container {
    top: 45%;
  }

  .image-container img {
    max-height: 300px;
    max-width: 400px;
  }
}

@media (max-width: 575.98px) {
  .logo-image {
    height: 200px;
    width: 200px;
  }

  .image-container {
    top: 35%;
  }

  .image-container img {
    max-height: 200px;
    max-width: 300px;
  }
}