.box-cart-product {
  margin-top: 30px;
  border: 2px solid #fff;
  border-radius: 20px;
  padding: 30px;
  color: white;
}

.information-cart-product {
  color: #e94514;
  font-weight: bold;
}

.name-cart-product {
  font-size: 30px;
}

.price-cart-product {
  margin-top: 15px;
  color: #fff;
  font-size: 25px;
}

.quantity-cart-product {
  margin-top: 15px;
  color: #fff;
  font-size: 20px;
}

.quantity-cp {
  font-size: 25px;
  margin-left: 10px;
}

.ingredients-container-cart-prduct {
  margin-top: 15px;
  display: flex;
}

.type-cart-product {
  font-size: 20px;
  font-weight: bold;
}

.ingredients-left-cart-product {
  display: flex;
  flex: 1;
}

.extra-right-cart-product {
  display: flex;
  flex: 2;
}

.ingredients-selection-cart-product,
.extra-selection-cart-product {
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  color: #e94514;
  font-weight: bold;
  font-size: 20px;
}

.delete-cart-product {
  margin-top: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.delete-button-cart-product {
  border: 5px solid #e94514;
  border-radius: 20px;
  height: 40px;
  width: 140px;
  font-weight: bold;
  color: #e94514;
  font-size: 16px;
}

.space-cart-product {
  margin-top: 20px;
}

.button-drink-cart-product {
  border: none;
  background-color: inherit;
  font-family: inherit;
  font-size: inherit;
  color: #e94514;
  font-size: 20px;
  font-weight: bold;
}

.align-item-cart-product {
  display: flex;
}

.space-right-cart-product {
  margin-right: 10px;
}

.container-cell {
  display: none;
}

@media (max-width: 575.98px) {
  .box-cart-product {
    margin-top: 10px;
    padding: 10px;
  }

  .name-cart-product {
    font-size: 24px;
  }

  .price-cart-product {
    font-size: 20px;
  }

  .quantity-cart-product {
    font-size: 20px;
  }

  .quantity-cp {
    font-size: 20px;
  }

  .type-cart-product {
    font-size: 18px;
    margin-bottom: 0;
  }

  .ingredients-container-cart-prduct {
    flex-direction: column;
  }

  .ingredients-selection-cart-product,
  .extra-selection-cart-product {
    font-size: 15px;
  }

  .delete-button-cart-product {
    border: 2px solid #e94514;
    width: 100%;
    height: 35px;
  }

  .ingredients-left-cart-product,
  .extra-right-cart-product {
    flex-direction: column;
  }

  .extra-right-cart-product {
    margin-top: 30px;
  }

  .space-right-cart-product {
    font-size: 18px;
  }

  .sauces {
    font-size: 15px;
    text-align: right;
  }

  .container-cell {
    display: flex;
    flex-direction: column;
  }

  .space-right-cart-product {
    margin-right: 0;
  }

  .pc-container {
    display: none;
  }

  .button-drink-cart-product {
    font-size: 15px;
  }
}