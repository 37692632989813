.button-cart {
  height: 55px;
  width: 480px;
  color: green;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
  border: 5px solid green;
  color: green;
  border-radius: 20px;
  font-family: inherit;
}

.button-continue-cart {
  height: 55px;
  width: 480px;
  color: #e94514;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
  border: 5px solid #e94514;
  color: #e94514;
  border-radius: 20px;
  font-family: inherit;
}

.product-list-cart {
  padding: 0 50px;
}

.confirm-cart {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.aic-cart-confirm-bottom {
  position: absolute;
  top: 0;
}

.container-popup-cart {
  background-image: linear-gradient(to bottom, #000 50%, #333 100%);
  padding: 50px;
  height: 340px;
  width: 400px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  margin: -100px -250px;
  border-radius: 20px;
  border: 2px solid #fff;
}

.delivery-container {
  margin-left: -50px;
  margin-bottom: 20px;
}

.question-cart {
  color: #fff;
  font-weight: bold;
  font-size: 35px;
  text-align: center;
}

.container-button-popup-cart {
  margin-top: 20px;
  display: flex;
}

.button-popup-cart {
  height: 45px;
  width: 180px;
  border-radius: 20px;
  border: 5px solid #e94514;
  color: #e94514;
  font-size: 22px;
  font-weight: bold;
  margin: 0 10px;
}

.totale-price-cart {
  color: #fff;
  font-size: 40px;
  font-weight: bold;
}

.total-cart {
  text-align: center;
  margin-top: 25px;
}

.total-text-cart {
  font-size: 40px;
  font-weight: bold;
  color: #e94514;
}

.link-cell,
.button-cart-cell {
  display: none;
}

.bottom-button-cart-cell {
  display: none;
}

.label-delivery {
  font-size: 20px;
  margin-right: 20px;
  margin-left: 50px;
  margin-top: 10px;
}

.select-hours {
  border: 1px solid #e94514;
  border-radius: 10px;
  height: 40px;
  width: 100px;
}

.select-city {
  border: 1px solid #e94514;
  border-radius: 10px;
  height: 30px;
  width: 200px;
  margin-top: 10px;
}

.move-left {
  margin-left: 0px;
}

@media (max-width: 1024px) {
  .button-cart {
    width: 350px;
    height: 60px;
    font-size: 30px;
    margin-top: 10px;
  }

  .button-continue-cart {
    width: 350px;
    height: 60px;
    font-size: 30px;
    margin-top: 10px;
  }

  .container-popup-cart {
    transform: translate(0, -30%);
  }
}

@media (max-width: 575.98px) {
  .label-delivery {
    font-size: 20px;
    margin-right: 20px;
    margin-left: 0px;
    margin-top: 0px;
  }

  .select-hours {
    border: 1px solid #e94514;
    border-radius: 10px;
    height: 40px;
    width: 100px;
  }

  .select-city {
    border: 1px solid #e94514;
    border-radius: 10px;
    height: 30px;
    width: 200px;
    margin-top: 10px;
  }

  .link,
  .button-cart {
    display: none;
  }

  .link-cell,
  .button-cart-cell {
    display: block;
    font-size: 20px;
  }

  .button-continue-cart {
    height: 35px;
    width: 150px;
    color: #e94514;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    border: 3px solid #e94514;
    color: #e94514;
    border-radius: 20px;
    font-family: inherit;
  }

  .button-cart-cell {
    height: 35px;
    width: 150px;
    color: green;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    border: 3px solid green;
    color: green;
    border-radius: 20px;
    font-family: inherit;
  }

  .container-popup-cart {
    height: 350px;
    width: 300px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
  }

  .question-cart {
    font-size: 25px;
  }

  .container-button-popup-cart {
    margin-top: 115px;
  }

  .container-button-popup-cart-delivery {
    margin-top: 290px;
  }

  .button-popup-cart {
    border: 2px solid #e94514;
    font-size: 18px;
    height: 40px;
    width: 130px;
  }

  .select-hour-cart {
    margin-top: 25px;
    position: absolute;
    margin-left: 50px;
  }

  .total-text-cart {
    font-size: 30px;
  }

  .totale-price-cart {
    font-size: 30px;
  }

  .bottom-button-cart-cell {
    display: inline;
    height: 35px;
    width: 150px;
    color: green;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    border: 3px solid green;
    color: green;
    border-radius: 20px;
    font-family: inherit;
    margin-bottom: 0;
    margin-top: 10px;
  }

  .button-confirm {
    margin-top: 10px;
  }
}

select {
  width: 70px;
  height: 25px;
}