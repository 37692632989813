.img-container-type1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.img-type1 {
  height: 300px;
  width: 300px;
}

.img-type2 {
  height: 250px;
  width: 500px;
}
.bottom-type1 {
  display: flex;
  padding: 0 50px;
}

.left-type1 {
  flex: 1;
}

.title-left-type1 {
  font-size: 30px;
  font-weight: bold;
  color: #e94514;
}

.right-type1 {
  flex: 1;
}

.name-right-type1 {
  font-size: 80px;
  font-weight: bold;
  color: #e94514;
  margin-bottom: 20px;
}

.price-right-type1 {
  font-size: 40px;
  font-style: italic;
  margin-bottom: 20px;
}

.description-right-type1 {
  font-size: 30px;
}

.button-inline-type1 {
  display: flex;
}

.salse-title-type1 {
  font-weight: bold;
  font-size: 28px;
  color: #e94514;
  margin-bottom: 10px;
}

.button-salse-type1 {
  border: none;
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  font-size: 24px;
  margin-right: 50px;
  font-weight: bold;
}

@media (max-width: 575.98px) {
  .img-type1 {
    height: 200px;
    width: 200px;
  }

  .title-left-type1 {
    font-size: 20px;
    margin-top: 20px;
  }

  .name-right-type1 {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .price-right-type1 {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .description-right-type1 {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .bottom-type1 {
    flex-direction: column-reverse;
    margin: -30px;
  }

  .img-type2 {
    height: 175px;
    width: 350px;
  }
}