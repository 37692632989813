.position {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 40vw;
}


.position-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 200px;
}

@media screen and (max-width: 2000px) {
  .position {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 35vw;
  }
}

@media screen and (max-width: 1700px) {
  .position {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 35vw;
  }
}

@media screen and (max-width: 1500px) {
  .position {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 33vw;
  }
}

@media screen and (max-width: 1300px) {
  .position {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 30vw;
  }
}

@media screen and (max-width: 1100px) {
  .position {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 25vw;
  }

  .position-2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 30px;
  }
}

@media screen and (max-width: 900px) {
  .position {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 18vw;
  }
}

@media screen and (max-width: 700px) {
  .position {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 9vw;
  }
}

@media (max-width: 575.98px) {
  .position {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 5%;
  }
}