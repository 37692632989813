.bg-number {
  background-color: green;
  color: #fff;
  font-weight: bold;
  height: 100vh;
  text-align: center;
  padding: 50px 0;
}

.top-number {
  font-size: 80px;
}

.center-number {
  font-size: 300px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  margin: -150px -350px;
}

.bottom-number {
  font-size: 40px;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
}

@media (max-width: 575.98px) {
  .top-number {
    font-size: 40px;
  }

  .center-number {
    font-size: 150px;
  }
}