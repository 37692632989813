.text-error {
  text-align: center;
  font-size: 40px;
}

.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-error {
  height: 85px;
  width: 330px;
  border: 8px solid #e94514;
  border-radius: 20px;
  margin-bottom: 35px;
  font-size: 28px;
  color: #e94514;
  font-weight: bold;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -25%);
}

@media (max-width: 575.98px) {
  .text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90vw;
  }

  .button-error {
    width: 250px;
    height: 55px;
    border: 5px solid #e94514;
    font-size: 24px;
  }
}