.top {
  display: flex;
  align-items: center;
  padding: 50px 50px;
  color: #e94514;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
}

.left {
  flex: 1;
}

.right {
  flex: 1;
  text-align: right;
}

.button-back {
  color: #e94514;
  font-size: 40px;
  font-weight: bold;
  background-color: inherit;
  border: none;
  font-family: inherit;
}

.information {
  color: #fff;
  font-size: 30px;
  font-weight: normal;
  margin-left: 50px;
  margin-top: -80px;
  margin-bottom: 30px;
}

.information-space {
  color: transparent;
  font-size: 30px;
  font-weight: normal;
  margin-top: 10px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .top {
    display: flex;
    align-items: center;
    padding: 30px 30px;
    color: #e94514;
    font-size: 35px;
    font-weight: bold;
  }
}

@media (max-width: 575.98px) {
  .top {
    padding: 20px 20px;
  }

  .right {
    position: absolute;
    right: 20px;
    top: 20px;
    padding-top: 0px;
  }

  .information {
    font-size: 18px;
    margin-left: 20px;
    margin-top: -30px;
  }
}