.list-ingredients-list {
  display: flex;
  flex-direction: column;
}

.select-ingredients-list {
  margin-top: 10px;
  font-size: 25px;
  border: none;
  background-color: inherit;
  font-family: inherit;
  text-align: left;
  padding: 0;
}

.select-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.select-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: red;
}

.select-container input:checked~.select-checkmark {
  background-color: lightgreen;
}

.select-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.select-container input:checked~.select-checkmark:after {
  display: block;
}

.select-container .select-checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: 575.98px) {
  .select-container {
    font-size: 18px;
  }

  .select-checkmark {
    height: 20px;
    width: 20px;
  }

  .select-container .select-checkmark:after {
    top: 1.5px;
    left: 6px;
  }
}