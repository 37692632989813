.button-bibita-drinks {
  margin-top: 40px;
  margin-left: 20px;
  border: 5px solid #e94514;
  border-radius: 20px;
  color: #e94514;
  background-color: #fff;
  height: 50px;
  width: 120px;
  font-weight: bold;
  font-size: 20px;
}

.button-bibita-drinks:hover {
  border: 5px solid #fff;
  color: #fff;
  background-color: #e94514;
}

.box-drinks {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.container-drinks {
  background-image: linear-gradient(to bottom, #000 50%, #333 100%);
  padding: 50px;
  height: 600px;
  width: 400px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  margin: -350px -250px;
  border-radius: 20px;
  border: 2px solid #fff;
}

.title-drinks {
  color: #e94514;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  margin-bottom: 15px;
}

.fixed-drinks {
  overflow-y: scroll;
  position: relative;
  height: 570px;
}

.fixed-drinks::-webkit-scrollbar {
  display: none;
}

.position-drinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.container-drinks-single {
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 60px;
  text-align: center;
}

.button-reset-drinks-single {
  border: 5px solid transparent;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  background-color: inherit;
  color: inherit;
}

.button-reset-drinks-single:hover {
  border: 5px solid #e94514;
  border-radius: 20px;
}

.close-drinks {
  font-size: 40px;
  position: absolute;
  margin-left: 400px;
  margin-top: -90px;
  cursor: pointer;
}

.single-contorno-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.addcart-contorni {
  height: 200px;
}

.label-contorni {
  font-size: 30px;
  text-align: center;
}

@media (max-width: 575.98px) {
  .font-size-drinks {
    font-size: 20px;
  }

  .close-drinks {
    margin-left: 350px;
  }
}