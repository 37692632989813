.logo {
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-image {
  width: 300px;
  height: 300px;
}

.button-container {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  margin: -10px -165px;
}

.button {
  display: flex;
  height: 85px;
  width: 330px;
  justify-content: center;
  align-items: center;
  border: 8px solid #e94514;
  border-radius: 20px;
  margin-bottom: 35px;
  font-size: 28px;
  color: #e94514;
  font-weight: bold;
  cursor: pointer;
}

.button-cell {
  display: none;
}

footer {
  color: #e94514;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
}

@media (max-width: 1024px) {
  .logo-image {
    width: 400px;
    height: 400px;
  }

  .button {
    width: 500px;
    height: 100px;
    font-size: 35px;
    transform: translate(-18%, 0);
  }

  footer {
    font-size: 30px;
  }
}

@media (max-width: 575.98px) {
  .logo-image {
    width: 200px;
    height: 200px;
  }

  .button {
    display: none
  }

  .button-cell {
    display: inline;
  }

  .button {
    position: absolute;
    left: 0;
    transform: translate(15%, 0);
    margin-top: 20px;
    width: 250px;
    height: 55px;
    border: 5px solid #e94514;
    font-size: 24px;
  }

  footer {
    font-size: 14px;
    bottom: 20px;
  }
}